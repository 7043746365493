var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"n-myft-ui n-myft-ui--instant"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideButtonText") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":41},"end":{"line":2,"column":100}}})) != null ? stack1 : "")
    + " "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"extraClasses") : depth0), depth0))
    + "\"\n			method=\"GET\"\n			data-myft-ui=\"instant\"\n			data-concept-id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"conceptId") : depth0), depth0))
    + "\"\n			action=\"/myft/add/"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"conceptId") : depth0), depth0))
    + "?instant=true\"\n			data-js-action=\"/__myft/api/core/followed/concept/"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"conceptId") : depth0), depth0))
    + "?method=put\">\n"
    + ((stack1 = container.invokePartial(require("../csrf-token/input.html"),depth0,{"name":"n-myft-ui/components/csrf-token/input","data":data,"indent":"\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "	<input type=\"hidden\" value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" name=\"name\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"directType") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":10,"column":1},"end":{"line":14,"column":8}}})) != null ? stack1 : "")
    + "	<button\n			aria-label=\"Get instant alerts for "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n"
    + ((stack1 = __default(require("../../../../node_modules/@financial-times/dotcom-server-handlebars/dist/node/helpers/ifAll.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInstantAlert") : depth0),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"cacheablePersonalisedUrl")),{"name":"ifAll","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":17,"column":3},"end":{"line":21,"column":13}}})) != null ? stack1 : "")
    + "	class=\"n-myft-ui__button\n	"
    + ((stack1 = alias4.call(depth0,alias2((depth0 != null ? lookupProperty(depth0,"variant") : depth0), depth0),{"name":"variant","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":1},"end":{"line":23,"column":53}}})) != null ? stack1 : "")
    + "\n	"
    + ((stack1 = alias4.call(depth0,alias2((depth0 != null ? lookupProperty(depth0,"size") : depth0), depth0),{"name":"size","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":1},"end":{"line":24,"column":47}}})) != null ? stack1 : "")
    + "\n	n-myft-ui__button--instant\n	n-myft-ui__button--instant-light\"\n	data-alternate-label=\"Stop instant alerts for "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"alternateText") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":28,"column":1},"end":{"line":36,"column":8}}})) != null ? stack1 : "")
    + "	data-concept-id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"conceptId") : depth0), depth0))
    + "\" \n	data-trackable=\"instant\"\n	title=\"Get instant alerts for "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n	name=\"_rel.instant\"\n	value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInstantAlert") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":41,"column":55}}})) != null ? stack1 : "")
    + "\"\n	type=\"submit\"\n	>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttonText") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":43,"column":2},"end":{"line":43,"column":63}}})) != null ? stack1 : "")
    + "</button>\n</form>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " n-myft-ui--instant--hide-text";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<input type=\"hidden\" value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"directType") : depth0), depth0))
    + "\" name=\"directType\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "	<input type=\"hidden\" value=\"http://www.ft.com/ontology/concept/Concept\" name=\"directType\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "			aria-pressed=\"true\"\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "			aria-pressed=\"false\"\n";
},"12":function(container,depth0,helpers,partials,data) {
    return " n-myft-ui__button--"
    + container.escapeExpression(container.lambda(depth0, depth0));
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	data-alternate-text=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"alternateText") : depth0), depth0))
    + "\"\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttonText") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":31,"column":1},"end":{"line":35,"column":8}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	data-alternate-text=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"buttonText") : depth0), depth0))
    + "\"\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "	data-alternate-text=\"Instant alerts\"\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "false";
},"23":function(container,depth0,helpers,partials,data) {
    return "true";
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"buttonText") : depth0), depth0));
},"27":function(container,depth0,helpers,partials,data) {
    return "Instant alerts";
},"29":function(container,depth0,helpers,partials,data) {
    return "<!-- Instant alert button hidden due to myFtApiWrite being off -->\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"flags"))) && lookupProperty(stack1,"myFtApiWrite")),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":47,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});