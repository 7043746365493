var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<form\n		class=\"n-myft-ui n-myft-ui--follow "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"extraClasses") : depth0), depth0))
    + "\"\n		method=\"GET\"\n		data-myft-ui=\"follow\"\n		data-concept-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conceptId") : depth0), depth0))
    + "\"\n		"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"collectionName") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":7,"column":85}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"followPlusDigestEmail") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":19,"column":9}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = container.invokePartial(require("../csrf-token/input.html"),depth0,{"name":"n-myft-ui/components/csrf-token/input","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		<div\n			class=\"n-myft-ui__announcement o-normalise-visually-hidden\"\n			aria-live=\"assertive\"\n			data-pressed-text=\"Now following "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + ".\"\n			data-unpressed-text=\"No longer following "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + ".\"\n		></div>\n		<button\n"
    + ((stack1 = __default(require("../../../../node_modules/@financial-times/dotcom-server-handlebars/dist/node/helpers/ifAll.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"setFollowButtonStateToSelected") : depth0),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"cacheablePersonalisedUrl")),{"name":"ifAll","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":28,"column":3},"end":{"line":56,"column":13}}})) != null ? stack1 : "")
    + "			class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"extraButtonClasses") : depth0), depth0))
    + "\n				n-myft-follow-button"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"variant") : depth0), depth0),{"name":"variant","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":4},"end":{"line":59,"column":61}}})) != null ? stack1 : "")
    + "\"\n			data-concept-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conceptId") : depth0), depth0))
    + "\" \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"followPlusDigestEmail") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":3},"end":{"line":63,"column":10}}})) != null ? stack1 : "")
    + "			data-trackable=\"follow\"\n			type=\"submit\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"buttonText") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":66,"column":3},"end":{"line":74,"column":12}}})) != null ? stack1 : "")
    + "</button>\n	</form>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-myft-tracking=\"collectionName="
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"collectionName") : depth0), depth0))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			action=\"/__myft/api/core/follow-plus-digest-email/"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"conceptId") : depth0), depth0))
    + "?method=put\"\n			data-myft-ui-variant=\"followPlusDigestEmail\"\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../node_modules/@financial-times/dotcom-server-handlebars/dist/node/helpers/ifAll.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"setFollowButtonStateToSelected") : depth0),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"cacheablePersonalisedUrl")),{"name":"ifAll","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":12,"column":3},"end":{"line":18,"column":13}}})) != null ? stack1 : "")
    + "		";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				action=\"/myft/remove/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conceptId") : depth0), depth0))
    + "\"\n				data-js-action=\"/__myft/api/core/followed/concept/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conceptId") : depth0), depth0))
    + "?method=delete\"\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				action=\"/myft/add/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conceptId") : depth0), depth0))
    + "\"\n				data-js-action=\"/__myft/api/core/followed/concept/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conceptId") : depth0), depth0))
    + "?method=put\"\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				aria-label=\"Remove "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " from myFT\"\n				title=\"Remove "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " from myFT\"\n				data-alternate-label=\"Add "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " to myFT\"\n				aria-pressed=\"true\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"alternateText") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":33,"column":4},"end":{"line":41,"column":11}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					data-alternate-text=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"alternateText") : depth0), depth0))
    + "\"\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttonText") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":36,"column":5},"end":{"line":40,"column":12}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						data-alternate-text=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"buttonText") : depth0), depth0))
    + "\"\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "						data-alternate-text=\"Add to myFT\"\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				aria-pressed=\"false\"\n				aria-label=\"Add "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " to myFT\"\n				title=\"Add "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " to myFT\"\n				data-alternate-label=\"Remove "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " from myFT\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"alternateText") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":47,"column":4},"end":{"line":55,"column":11}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttonText") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":50,"column":5},"end":{"line":54,"column":12}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return "						data-alternate-text=\"Added\"\n";
},"23":function(container,depth0,helpers,partials,data) {
    return " n-myft-follow-button--"
    + container.escapeExpression(container.lambda(depth0, depth0));
},"25":function(container,depth0,helpers,partials,data) {
    return "				data-trackable-context-messaging=\"add-to-myft-plus-digest-button\"\n";
},"27":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"buttonText") : depth0), depth0));
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../node_modules/@financial-times/dotcom-server-handlebars/dist/node/helpers/ifAll.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"setFollowButtonStateToSelected") : depth0),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"cacheablePersonalisedUrl")),{"name":"ifAll","hash":{},"fn":container.program(30, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":69,"column":4},"end":{"line":73,"column":16}}})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    return "Added";
},"32":function(container,depth0,helpers,partials,data) {
    return "Add to myFT";
},"34":function(container,depth0,helpers,partials,data) {
    return "	<!-- Add to myFT button hidden due to myFtApiWrite being off -->\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"flags"))) && lookupProperty(stack1,"myFtApiWrite")),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":79,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});