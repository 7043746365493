var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"myft-pin-divider\"></span>\n<div class=\"myft-pin-button-wrapper\">\n	<form method=\"post\" action=\"/__myft/api/core/prioritised/concept/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "?method="
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"prioritised") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":80},"end":{"line":4,"column":123}}})) != null ? stack1 : "")
    + "\" data-myft-prioritise>\n"
    + ((stack1 = container.invokePartial(require("../csrf-token/input.html"),depth0,{"name":"n-myft-ui/components/csrf-token/input","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		<input type=\"hidden\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" name=\"name\"> "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"directType") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":6,"column":53},"end":{"line":8,"column":100}}})) != null ? stack1 : "")
    + "\n		<div\n			class=\"n-myft-ui__announcement o-normalise-visually-hidden\"\n			aria-live=\"assertive\"\n			data-pressed-text=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " pinned in myFT.\"\n			data-unpressed-text=\"Unpinned "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " from myFT.\"\n		></div>\n		<button id=\"myft-pin-button__"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"myft-pin-button\" data-prioritise-button data-trackable=\"prioritised\" data-concept-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-prioritised=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"prioritised") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":15,"column":158},"end":{"line":15,"column":201}}})) != null ? stack1 : "")
    + "\"\n				aria-label=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"prioritised") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":58}}})) != null ? stack1 : "")
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"prioritised") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":16,"column":68},"end":{"line":16,"column":108}}})) != null ? stack1 : "")
    + " myFT\" aria-pressed=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"prioritised") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":16,"column":129},"end":{"line":16,"column":172}}})) != null ? stack1 : "")
    + "\" title=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"prioritised") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":16,"column":181},"end":{"line":16,"column":223}}})) != null ? stack1 : "")
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">\n		</button>\n	</form>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "delete";
},"4":function(container,depth0,helpers,partials,data) {
    return "put";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n		<input type=\"hidden\" value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"directType") : depth0), depth0))
    + "\" name=\"directType\"> ";
},"8":function(container,depth0,helpers,partials,data) {
    return "\n		<input type=\"hidden\" value=\"http://www.ft.com/ontology/concept/Concept\" name=\"directType\"> ";
},"10":function(container,depth0,helpers,partials,data) {
    return "true";
},"12":function(container,depth0,helpers,partials,data) {
    return "false";
},"14":function(container,depth0,helpers,partials,data) {
    return "Unpin";
},"16":function(container,depth0,helpers,partials,data) {
    return "Pin";
},"18":function(container,depth0,helpers,partials,data) {
    return "from";
},"20":function(container,depth0,helpers,partials,data) {
    return "in";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showPrioritiseButton") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":20,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});